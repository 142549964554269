@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Montserrat:wght@700&display=swap');

@font-face {
  font-family: Montserrat;
  src: url(assets/fonts/Montserrat-Regular.ttf);
}

body {
  display: block;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  border-radius: 0;
  background: none;
  vertical-align: top;
  font-size: 16px;
  font-family: Montserrat;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  width: 0;
}

html {
  font-family: "Montserrat";
}

body {
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

textarea {
  font-family: "Montserrat";
  resize: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

p {
  font-size: 18px;
  letter-spacing: 1px;
}

h2 {
  font-size: 32px;
  letter-spacing: 1px;
}

h3 {
  font-size: 28px;
  letter-spacing: 1px;
}

h4 {
  font-size: 24px;
  letter-spacing: 1px;
}

h5 {
  font-size: 20px;
  letter-spacing: 1px;
}

h6 {
  font-size: 18px;
  letter-spacing: 1px;
}

[pointer] {
  cursor: pointer;
}

button {
  cursor: pointer;
}

/* ----------------- 1.1 Classes ----------------- */

.G-red-color {
  color: #870000;
}

.G-orange-color {
  color: #ffb137;
}

.G-link-btn {
  padding: 6px 25px;
  border: 1px solid;
  border-radius: 20px;
  color: #fff;
}

.G-db {
  display: block !important;
}

.G-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.G-float-left {
  float: left !important;
}

.G-float-right {
  float: right !important;
}

.G-wave {
  position: relative;
  z-index: 0;
}

.G-wave:hover {
  color: #000;
  transition-duration: 0.3s;
}

.G-wave::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  z-index: -2;
  border-radius: 3px;
  transition: 0.3s all ease;
  background-color: #fff;
}

.G-wave:hover::before {
  left: 0;
  right: 0;
  opacity: 1;
}

.G-cover {
  width: 100%;
  height: calc(100vh - 100px);
  min-height: 500px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #000;
  background-position: 50% 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.G-cover::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.G-cover-logo {
  width: 230px;
  height: 200px;
  z-index: 1;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.G-cover-text {
  margin-top: 100px;
  text-align: center;
  z-index: 1;
}

.G-cover-text p {
  margin: 30px 0;
  font-size: 40px;
  color: #fff;
}
